<template>
  <div
    :style="$store.state.appConfig.layout.skin == 'dark' ? 'background-color: #0B0B0B' : 'background-color: #E5E7EB'"
  >
    <b-row>
      <b-col :md="moduleDetails ? '9' : '12'" class="overflow-hidden">
        <div class="w-100 d-flex align-items-center justify-content-between">
          <div class="mb-2">
            <h3>Categories</h3>
          </div>
          <div class="d-flex justify-content-center">
            <div
              @click.prevent="slidePrev"
              :class="$store.state.appConfig.layout.skin === 'dark' ? 'd-flex justify-content-center align-items-center mr-2 rounded-circle bg-custom-dark p-1 cursor-pointer text-primary' : 'd-flex justify-content-center align-items-center mr-2 rounded-circle bg-white p-1 cursor-pointer text-primary'"
            >
              <i class="fa-solid fa-arrow-left"></i>
            </div>
            <div
              @click.prevent="slideNext"
              :class="$store.state.appConfig.layout.skin === 'dark' ? 'd-flex justify-content-center align-items-center mr-2 rounded-circle bg-custom-dark p-1 cursor-pointer text-primary' : 'd-flex justify-content-center align-items-center mr-2 rounded-circle bg-white p-1 cursor-pointer text-primary'"
            >
              <i class="fa-solid fa-arrow-right"></i>
            </div>
          </div>
        </div>
        <b-row v-if="load">
          <b-col md="12" class="d-flex justify-content-center">
            <b-spinner variant="primary" label="Spinning"></b-spinner>
          </b-col>
        </b-row>
        <b-row v-else>
          <b-col v-if="courses" ref="hooperWrapper">
            <hooper
              :settings="hooperSettings"
              :infiniteScroll="false"
              ref="carousel"
              class="mb-1 w-100 hooper1"
              @slide="updateCarousel"
            >
              <slide class="slide" style="max-height: 290px">
                <div @click="makeSlideActive(0, item)">
                  <div class="p-2">
                    <img src="@/assets/images/learning/modules/quests.png" alt class="w-100" />
                  </div>
                  <div class="card-title">
                    <h4>All Quests</h4>
                    <p class="mb-0">{{modules.total}} modules</p>
                  </div>
                </div>
              </slide>
              <slide
                class="slide"
                v-for="(item, index) in courses.data"
                :key="item.id"
                style="max-height: 290px"
              >
                <div @click="makeSlideActive(index, item)" class="cursor-pointer w-100 h-100">
                  <div class="p-2">
                    <img :src="item.cover_image" alt class="w-100" />
                  </div>
                  <div class="card-title">
                    <h4>{{item.name}}</h4>
                    <p class="mb-0">{{item.total_modules}} modules</p>
                  </div>
                </div>
              </slide>
            </hooper>
          </b-col>
        </b-row>
        <b-row class="pt-0 pt-xl-3">
          <b-col cols="12" class="d-flex align-items-center justify-content-between pt-1 pb-1">
            <div>
              <h3>All Quests</h3>
            </div>
            <div class="d-flex justify-content-center">
              <div
                @click.prevent="slidePrev2"
                :class="$store.state.appConfig.layout.skin === 'dark' ? 'd-flex justify-content-center align-items-center mr-2 rounded-circle bg-custom-dark p-1 cursor-pointer text-primary' : 'd-flex justify-content-center align-items-center mr-2 rounded-circle bg-white p-1 cursor-pointer text-primary'"
              >
                <i class="fa-solid fa-arrow-left"></i>
              </div>
              <div
                @click.prevent="slideNext2"
                :class="$store.state.appConfig.layout.skin === 'dark' ? 'd-flex justify-content-center align-items-center mr-2 rounded-circle bg-custom-dark p-1 cursor-pointer text-primary' : 'd-flex justify-content-center align-items-center mr-2 rounded-circle bg-white p-1 cursor-pointer text-primary'"
              >
                <i class="fa-solid fa-arrow-right"></i>
              </div>
            </div>
          </b-col>
          <b-col md="12" class="d-flex justify-content-center" v-if="modulesLoad">
            <b-spinner variant="primary" label="Spinning"></b-spinner>
          </b-col>
          <b-col cols="12" class="h-100 mb-1 mb-md-0" v-else>
            <hooper :settings="hooperSettings2" ref="carousel2" @slide="updateCarousel2">
              <slide
                class="slide px-1"
                v-for="item in modules.data"
                :key="item.id"
                style="max-height: 270px"
              >
                <div
                  :class="$store.state.appConfig.layout.skin === 'dark' ? 'custom-lesson-container-dark cursor-pointer' : 'custom-lesson-container cursor-pointer'"
                  :id="`article${item.id}`"
                  @click="updateModuleDetails(item)"
                >
                  <div class="img-holder">
                    <img :src="item.cover_image" alt width="100%" />
                  </div>
                  <div class="content-holder">
                    <div class="w-100 d-flex justify-content-between">
                      <div class="mb-1">
                        <b-badge pill variant="light" class="border">
                          <span class="text-uppercase pt-1" style="color: #080">new</span>
                        </b-badge>
                      </div>
                      <div>
                        <small>{{item.total_topics }}{{item.total_topics == 1 ? ' Lesson' : ' Lessons' }}</small>
                      </div>
                    </div>
                    <div class="font-weight-bolder">{{item.name}}</div>
                    <div class="html-wrapper">
                      <small v-html="item.description"></small>
                    </div>
                  </div>
                </div>
              </slide>
            </hooper>
          </b-col>
        </b-row>
      </b-col>
      <b-col md="3" v-if="moduleDetails" style="min-height: 100% !important;">
        <div
          :class="$store.state.appConfig.layout.skin == 'dark' ?  'dark-custom-articale p-1 h-100' : 'bg-white p-1 h-100'"
          style="border-radius: 20px"
        >
          <b-row class="px-25">
            <b-col md="12" class="mb-1 px-1">
              <div
                class="d-flex justify-content-center align-items-center overflow-hidden w-100"
                style="height: 170px; border: 1px transparent solid; border-radius: 15px"
              >
                <img :src="moduleDetails.cover_image" width="100%" alt />
              </div>
            </b-col>
            <b-col md="12" class="mb-2 px-1">
              <h3>{{moduleDetails.name}}</h3>
              <p>{{moduleDetails.course.name}}</p>
              <div class="d-flex align-items-center">
                <feather-icon icon="FileTextIcon" size="20" class="cursor-pointer mr-1" />
                <span class="text-primary">{{moduleDetails.total_topics}} lessons</span>
              </div>
            </b-col>
            <b-col md="12" class="mb-1 px-1">
              <h3>Description</h3>
              <span v-html="moduleDetails.description" style="word-wrap: break-word;"></span>
            </b-col>
            <b-col md="12" class="d-flex justify-content-center mb-1 px-1">
              <b-button
                variant="primary"
                :class="'rounded-pill px-5'"
                v-if="hasPermission('Access Student Learning') && $route.name == 'student_learning'"
                @click="enrol(moduleDetails)"
              >{{ moduleDetails.assigned ? 'Continue' : 'Request Enroll' }}</b-button>
              <b-link
                v-if="hasPermission('Assign Users Course') && $route.name == 'learning'"
                :tooltip="'none'"
                class="alert-link cutom-alert-link mx-1"
                :to="{ name: 'preview_module', params: { id: moduleDetails.id} }"
              >
                <b-button variant="primary" :class="'rounded-pill'">Assign Learning</b-button>
              </b-link>
            </b-col>
            <b-col
              md="12"
              class="d-flex justify-content-center"
              v-if="moduleDetails.overview_video"
            >
              <video width="100%" controls class="rounded-lg" :key="moduleDetails.overview_video">
                <source :src="moduleDetails.overview_video" type="video/mp4" />
                <source :src="moduleDetails.overview_video" type="video/ogg" />Your browser does not support HTML video.
              </video>
            </b-col>
          </b-row>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { Hooper, Slide } from "hooper";
import "hooper/dist/hooper.css";
import { mapGetters } from "vuex";
import {
  BCard,
  BRow,
  BCol,
  BCardText,
  BButton,
  BLink,
  BListGroup,
  BListGroupItem,
  BCardTitle,
  BCardBody,
  BTab,
  BTabs,
  BSpinner,
  BBadge,
  BCardImg,
  BAlert
} from "bootstrap-vue";
import { filter } from "postcss-rtl/lib/affected-props";
export default {
  name: "RecommendedLearning",
  components: {
    BCard,
    BRow,
    BCol,
    BCardText,
    BButton,
    BLink,
    BListGroup,
    BListGroupItem,
    BCardTitle,
    BCardBody,
    BTab,
    BTabs,
    BSpinner,
    BAlert,
    BBadge,
    Hooper,
    Slide,
    BCardImg
  },
  computed: {
    ...mapGetters({
      courses: "courses/items",
      modules: "modules/items",
      load: "courses/load",
      modulesLoad: "modules/load"
    })
  },
  data() {
    return {
      moduleDetails: null,
      carouselData: 0,
      filter: { course_id: null },
      hooperSettings: {
        itemsToShow: 2,
        itemsToSlide: 1,
        centerMode: false,
        mouseDrag: false,
        wheelControl: false,
        infiniteScroll: false,
        breakpoints: {
          500: {
            centerMode: false,
            itemsToShow: 3
          },
          800: {
            itemsToShow: 4,
            pagination: "fraction"
          },
          1000: {
            itemsToShow: 5,
            pagination: "fraction"
          }
        }
      },
      hooperSettings2: {
        itemsToShow: 1,
        centerMode: false,
        mouseDrag: false,
        wheelControl: false,
        itemsToSlide: 5,
        infiniteScroll: false,
        breakpoints: {
          500: {
            centerMode: false,
            itemsToSlide: 1,
            itemsToShow: 1
          },
          800: {
            itemsToShow: 4,
            itemsToSlide: 4,
            pagination: "fraction"
          },
          1000: {
            itemsToShow: 5,
            pagination: "fraction"
          }
        }
      }
    };
  },
  watch: {
    carouselData() {
      this.$refs.carousel.slideTo(this.carouselData);
    }
  },
  methods: {
    makeSlideActive(index, item) {
      this.$refs.carousel.slideTo(index + 1);
    },
    slidePrev(event, ss) {
      this.$refs.carousel.slidePrev();
    },
    slideNext() {
      this.$refs.carousel.slideNext();
    },
    slidePrev2(event, ss) {
      this.$refs.carousel2.slidePrev();
    },
    slideNext2() {
      this.$refs.carousel2.slideNext();
    },
    updateCarousel(payload) {
      this.filter.course_id =
        payload.currentSlide == 0
          ? null
          : this.courses.data[payload.currentSlide - 1].id;
      this.moduleDetails = null;
      this.$store.dispatch("modules/list", { ...this.filter }).then(_ => {});
    },
    updateCarousel2(payload) {
      this.myCarouselData = payload.currentSlide;
    },
    removeActiveArticleClass() {
      const activeElements = document.querySelectorAll(".active-artical");
      activeElements.forEach(element => {
        element.classList.remove("active-artical");
      });
    },
    updateModuleDetails(item) {
      this.removeActiveArticleClass();
      if (this.moduleDetails && this.moduleDetails.id == item.id) {
        this.moduleDetails = null;
        var element = document.getElementById(`article${item.id}`);
        element.classList.remove("active-artical");
      } else {
        this.moduleDetails = item;
        var element = document.getElementById(`article${item.id}`);
        element.classList.add("active-artical");
      }
    },
    disableWheelScroll() {
      const hooperElement = this.$refs.hooperWrapper;

      hooperElement.addEventListener(
        "wheel",
        event => {
          event.stopPropagation();
          event.preventDefault();
        },
        { passive: false }
      );
    },
    enrol(item) {
      if (this.moduleDetails.assigned) {
        this.$router.push({
          name: "module_introduction",
          params: { id: item.id }
        });
      } else {
        this.$store
          .dispatch("lessonPlans/enrolUser", item.id)
          .then(res => {
            this.$swal({
              icon: "success",
              title: `<h4> ${res.message} </h4>`,
              showConfirmButton: true,
              confirmButtonColor: "#E84185",
              allowOutsideClick: true
            });
          })
          .catch(error => {
            if (error.response) {
              this.$swal({
                icon: "error",
                title: `<h4>${error.response.data.error}</h4>`,
                showConfirmButton: true,
                confirmButtonColor: "#E84185",
                allowOutsideClick: true
              });
            }
          });
      }
    }
  },
  beforeMount() {
    this.$store.dispatch("courses/list");
    this.$store.dispatch("modules/list");
  },
  mounted() {
  }
};
</script>
<style lang="css" scoped>
.hooper {
  height: auto !important;
}

.hooper1 .slide {
  /* background-color: black; */
  position: relative !important;
}
.hooper-slide {
  width: 300px;
}
.hooper1 .is-current {
  margin-top: -40px;
}
.nav-arrows {
  background-color: white;
  /* padding: 10px; */
  border-radius: 10%;
  width: 40px;
  height: 40px;
}
.hooper1 .card-title {
  position: absolute !important;
  bottom: 8%;
  left: 13%;
  color: white;
  /* font-size: 1vw; */
}
.hooper1 .card-title h4 {
  color: white;
  margin-bottom: 0;
}

.card-body {
  padding-bottom: 10px !important;
  background-color: #fff;
  border-radius: 15px !important;
}
.card-title {
  margin-bottom: 10px !important;
}
article {
  border-radius: 60px !important;
  background: transparent !important;
}
.card-img-top {
  margin-bottom: -21px !important;
}

.custom-lesson-container {
  position: relative;
  width: 100%;
  border: 2px solid #ffffff;
  border-radius: 20px;
  height: 255px;
  overflow: hidden;
}

.img-holder {
  height: 165px;
  background-color: #fff;
  display: flex;
  justify-content: center;
  overflow: hidden;
}

.img-holder img {
  height: 100%;
  width: auto;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
}

.content-holder {
  position: absolute;
  height: 115px;
  width: 100%;
  z-index: 999;
  padding: 15px;
  background-color: #fff;
  margin-top: -25px;
  border-radius: 20px;
}

.active-artical {
  border: 2px solid #e84185;
  color: #fff !important;
}

.active-artical .content-holder {
  border: 2px solid #e84185 !important;
  color: #fff !important;
  background-color: #e84185 !important;
}

.active-artical h4 {
  color: #fff !important;
}

.active-artical small {
  color: #fff !important;
}
.custom-lesson-container-dark {
  position: relative;
  width: 100%;
  border: 2px solid #2f2f2ffc;
  border-radius: 20px;
  height: 255px;
  overflow: hidden;
}

.custom-lesson-container-dark .img-holder {
  height: 165px;
  background-color: #3a3a3a;
  display: flex;
  justify-content: center;
  overflow: hidden;
}

.custom-lesson-container-dark .content-holder {
  position: absolute;
  height: 115px;
  width: 100%;
  z-index: 999;
  padding: 15px;
  background-color: #0b0b0bfc;
  margin-top: -25px;
  border-radius: 20px;
}

/* .custom-lesson-container-dark .text-muted{
    color: #9CA3AF !important;
} */

.custom-lesson-container-dark .active-artical small {
  color: #fff !important;
}

/* .active-artical img{
    border: 2px solid #E84185 !important;
} */

.img-holder {
  height: 165px;
  background-color: #fff;
  display: flex;
  justify-content: center;
  overflow: hidden;
}

.img-holder img {
  height: 100%;
  width: auto;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
}

.content-holder {
  position: absolute;
  height: 115px;
  width: 100%;
  z-index: 999;
  padding: 15px;
  background-color: #fff;
  margin-top: -25px;
  border-radius: 20px;
}

.active-artical {
  border: 2px solid #e84185;
  color: #fff !important;
}

.active-artical .content-holder {
  border: 2px solid #e84185 !important;
  color: #fff !important;
  background-color: #e84185 !important;
}

.active-artical h4 {
  color: #fff !important;
}

.active-artical small {
  color: #fff !important;
}

/deep/.hooper-list {
  overflow: visible !important;
  width: 100%;
  height: 100%;
}
.html-wrapper{
  overflow: hidden;
  height: 37px;
}
</style>